.header_action {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.header_action button:first-child {
    background-color: #cdfcf2;
    color: var(--PrimaryColor);
    display: flex;
    border: none;
    align-items: center;
    border-radius: 10px;
    gap: 0.5rem;
    padding: 0.5rem;
    font-weight: bold;
}

.All_Categories {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    padding: 0.2rem;
    width: fit-content;
    border: 1px solid #e0e2e7;
    border-radius: 10px;
    min-width: 330px;
}
.All_Categories button {
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;
    background-color: inherit;
    border: none;
    color: #667085;
    font-size: 14px;
    font-weight: 500;
}
.active,
.All_Categories button:hover {
    color: var(--PrimaryColor) !important;
    background-color: #f4ecfb !important;
    font-weight: 530 !important;
}
.product_filters {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}
.form_control {
    position: relative;
    width: 19rem;
}
.form_control input {
    width: 100%;
    border: 1px solid #e0e2e7;
    height: 2.4rem;
    border-radius: 10px;
    text-indent: 2rem;
    outline: var(--PrimaryColor) !important;
}
.filter_right {
    display: flex;
    gap: 0.5rem;
}

.filter_right button {
    color: #667085;
    border: 1px solid #e0e2e7;
    background-color: white;
    border-radius: 10px;
    padding: 0 0.7rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}
.filter_right button:hover {
    background-color: var(--PrimaryColor);
    color: white;
}
.search {
    position: absolute;
    top: 11px;
    left: 10px;
    font-size: 1.2rem;
    color: lightgray;
}
.Add_form_wrapper_Product form {
    display: grid;
    grid-template-columns: 1fr 300px;
    align-content: baseline;
    height: fit-content;
    position: relative;
}
.form_left {
    display: grid;
    gap: 1rem;
}
.Product_wrapper_right {
    display: grid;
    gap: 0.5rem;
    align-content: baseline;
}
.form_pricing,
.form_inventory {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 1.5rem;
    align-content: baseline;
}
.form_inventory {
    grid-template-columns: repeat(3, 1fr);
}
/* --------------Add Pandit---------- */
.input-number {
    position: relative;
}
.input-number select {
    border: none;
    background-color: #3b3b5a;
    outline: none;
    scrollbar-width: none;
}
.input-number select option {
    background-color: #3b3b5a;
}
.input-number select::-webkit-scrollbar {
    display: none;
}
.input-number::-webkit-scrollbar {
    display: none;
}
.input-number .icon {
    top: 14px;
    left: 12px;
}
.input-number input {
    text-indent: 4rem;
}
.input-number input {
    text-indent: 4rem;
}
.select-btn {
    color: black;
}

.select-btn > button {
    border: none !important;
    padding: 0 !important;
    background-color: inherit !important;
}
.select-btn input {
    border: 1px solid #3b3b5a;
    /* background-color: #3b3b5a !important; */
    border-radius: 16px;
}
.flag_select {
    position: absolute !important;
    left: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    /* // width: 100%; */
}
.reactSelect_custom {
    position: relative;
    display: grid;
    gap: 0.5rem;
}
.reactSelect_custom span {
    font-weight: 500;
    font-family: "Times New Roman", Times, serif;
}
.reactSelect_custom input {
    text-indent: 4rem;
    /* // width: 100%; */
    min-width: 15rem;
}
.reactSelect_custom input::-webkit-outer-spin-button,
.reactSelect_custom input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 500px) {
    .All_Categories_wrapper {
        width: 100%;
        max-width: 70vw;
        overflow: auto;
    }
}
@media screen and (max-width: 1000px) {
    .Add_form_wrapper_Product form,
    .form_pricing,
    .form_inventory {
        grid-template-columns: 1fr;
    }
}
