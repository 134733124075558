@import url("../Styles/Variable.css");
* {
    margin: 0;
    padding: 0;
    /* transform: scale(0.9);  */
}

body {
    font-family: "Barlow", sans-serif;
    font-optical-sizing: auto;
    width: 100%;
}
.colors_theme {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}
.colors_theme_wrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.selec {
    cursor: pointer;
    margin-top: 0.5rem;
}
.colors_theme {
    position: absolute;
    right: 0px;
    top: 40px;
    z-index: 10;
    background-color: white;
    border-radius: 10px;
    /* height: 100%; */
}
.colors_theme {
    display: grid;
    gap: 0.2rem;
}
.colors_theme div {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.3rem 1.5rem 0.3rem 0.5rem;
    text-transform: capitalize;
    font-size: 0.9rem;
}
.colors_theme div:hover {
    background-color: lightgray;
}
.colors_theme div span {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    display: flex;
}
.each_theme:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.quill_custom .ql-container {
    border: 1px solid gray; /* Gray border color */
    /* Light cream background */
    border-radius: 4px; /* Optional: Add rounded corners */
    padding: 8px; /* Optional: Add padding inside the editor */
    min-height: 12rem;
    height: 100%;
}

.quill_custom .ql-toolbar {
    border: 1px solid gray; /* Match toolbar border to editor border */
    border-bottom: none; /* Prevent double borders */
    border-radius: 4px 4px 0 0; /* Match rounded corners for top */
}

.quill_custom .ql-container.ql-snow {
    border-top: none; /* Remove border between toolbar and editor */
}
.title_wrapper {
    padding: 0 1rem;
}
.title_wrapper > h3 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.6rem;
}
.title_wrapper p,
.title_wrapper > div {
    margin-top: 1rem;
}
/* Swwt alert  */
.swal-title {
    margin: 0px;
    font-size: 18px;
}
.swal-text {
    background-color: #fefae3;
    padding: 17px;
    border: 1px solid #f0e1a1;
    display: block;
    margin: 10px 22px;
    text-align: center;
    color: #61534e;
}
.swal-icon {
    margin: 5px auto;
}
/* Customize pagination styles */
.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.pagination li {
    margin-right: 10px;
}

.pagination li a {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid #ccc;
    color: black;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
}

.pagination li a:hover {
    background-color: #a9a3a3;
    color: #fff;
}

.pagination .active a {
    background-color: #a9a3a3;
    color: #fff;
    font-weight: bold;
}
.pagination .disabled a {
    background-color: lightgray;
    color: white;
    border-color: transparent;
}
.pagination .disabled a:hover {
    background-color: lightgray;
}
/* Styling previous and next buttons */
.pagination .previous a,
.pagination .next a {
    background-color: #1e87db;
    border: 1px solid #1e87db;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}

/* Pagination separator style (e.g., "..." between pages) */
.pagination .break a {
    border: none;
    color: #1e87db;
}

/* remove arrow for input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
