.Container {
    border: 2px dashed #e0e2e7;
    display: grid;
    gap: 0.6rem;
    align-items: center;
    justify-items: center;
    padding: 2rem 0;
    text-align: center;
    background-color: #f9f9fc;
}
.Container button {
    background-color: #f4ecfb;
    color: var(--PrimaryColor);
    padding: 0.5rem 0.8rem;
    border: none;
    border-radius: 5px;
    font-size: 14px;
}
.Container .img {
    background-color: var(--PrimaryColor);
    display: grid;
    align-items: center;
    justify-items: center;
    height: 2rem;
    width: 2rem;
    border-radius: 5px;
}
.Container.img img {
    width: 1rem;
    height: 1rem;
}
.Container p {
    font-size: 14px;
    color: #858d9d;
}

/* -----------Profile--------- */
