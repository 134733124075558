.roles_wrapper {
    width: 100%;
    max-width: 80vw;
}
.roles_wrapper h3,
.heading {
    padding-top: 0.5rem;
    font-size: 1.2rem;
    color: var(--HeadingColor);
    font-weight: 600;
}
.heading {
    margin: 0.5rem 0;
}
.role_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.role_content div {
    display: flex;
    justify-content: space-between;
}
.roles_wrapper table {
    width: 100%;
    border-collapse: collapse;
}
.roles_wrapper table thead {
    text-align: left;
}
.roles_wrapper table thead tr {
    margin: 0.5rem 0;
}
.roles_wrapper table tr td {
    border-bottom: 1px solid #ccc;
    padding: 0.6rem;
    color: #323d57;
}
@media screen and (max-width: 767px) {
    .Container > header h3,
    .Add_form_wrapper > h3,
    .Add_header h3 {
        font-size: clamp(1.3rem, 1.8rem, 4vw);
    }
}
