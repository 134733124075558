.header_action {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.header_action button:first-child {
    background-color: #cdfcf2;
    color: var(--PrimaryColor);
    display: flex;
    border: none;
    align-items: center;
    border-radius: 10px;
    gap: 0.5rem;
    padding: 0.5rem;
    font-weight: bold;
}

.All_Categories {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    padding: 0.2rem;
    width: fit-content;
    border: 1px solid #e0e2e7;
    border-radius: 10px;
    min-width: 330px;
}
.All_Categories button {
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;
    background-color: inherit;
    border: none;
    color: #667085;
    font-size: 14px;
    font-weight: 500;
}
.active,
.All_Categories button:hover {
    color: var(--PrimaryColor) !important;
    background-color: #f4ecfb !important;
    font-weight: 530 !important;
}
.product_filters {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}
.form_control {
    position: relative;
    width: 19rem;
}
.form_control input {
    width: 100%;
    border: 1px solid #e0e2e7;
    height: 2.4rem;
    border-radius: 10px;
    text-indent: 2rem;
    outline: var(--PrimaryColor) !important;
}
.filter_right {
    display: flex;
    gap: 0.5rem;
}

.filter_right button {
    color: #667085;
    border: 1px solid #e0e2e7;
    background-color: white;
    border-radius: 10px;
    padding: 0 0.7rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}
.filter_right button:hover {
    background-color: var(--PrimaryColor);
    color: white;
}
.search {
    position: absolute;
    top: 11px;
    left: 10px;
    font-size: 1.2rem;
    color: lightgray;
}
.Add_form_wrapper_Product form {
    display: grid;
    grid-template-columns: 1fr 300px;
    align-content: baseline;
    height: fit-content;
    position: relative;
}
.form_left {
    display: grid;
    gap: 1rem;
}
.Product_wrapper_right {
    display: grid;
    gap: 0.5rem;
    align-content: baseline;
}
.form_pricing,
.form_inventory {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 1.5rem;
    align-content: baseline;
}
.form_inventory {
    grid-template-columns: repeat(3, 1fr);
}
.Tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.3rem;
}
.Tags p {
    color: black;
    font-size: 0.9rem;
    padding: 0.3rem 0.5rem;
    border-radius: 10px;
}
.all_Tags {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.3rem;
    flex-wrap: wrap;
}
.all_Tags span {
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
    background-color: #d6def0;
    color: black;
    user-select: none;
}
@media screen and (max-width: 500px) {
    .All_Categories_wrapper {
        width: 100%;
        max-width: 70vw;
        overflow: auto;
    }
}
@media screen and (max-width: 1000px) {
    .Add_form_wrapper_Product form,
    .form_pricing,
    .form_inventory {
        grid-template-columns: 1fr;
    }
}
