:root {
    --PrimaryColor: #12715b;
    --secondaryColor: #e9faf7;
    --tableheaderBg: #12715b;
    --tableHeaderColor: #ffffff;
    --tableDataColor: #667085;
    --BorderColor: #12715b;
    --HeadingColor: #464255;
    --headerSize: 1.6rem;
}

.blue {
    --PrimaryColor: #1e3a8a; /* Dark Blue */
    --secondaryColor: #e0f2fe; /* Light Blue */
    --tableheaderBg: #1e3a8a; /* Dark Blue */
    --tableHeaderColor: #ffffff; /* White for header text */
    --tableDataColor: #475569; /* Muted Blue-Grey for table data */
    --BorderColor: #1e3a8a; /* Dark Blue */
    --HeadingColor: #334155; /* Slightly darker Blue-Grey for headings */
    --headerSize: 1.6rem; /* Keeping header size the same */
}

.pink {
    --PrimaryColor: #d63384; /* Dark Pink */
    --secondaryColor: #f8d7da; /* Light Pink */
    --tableheaderBg: #d63384;
    --tableHeaderColor: #ffffff;
    --tableDataColor: #9c3a72; /* Muted Pink */
    --BorderColor: #d63384;
    --HeadingColor: #9c3a72;
    --headerSize: 1.6rem;
}

.violet {
    --PrimaryColor: #6a0dad; /* Dark Violet */
    --secondaryColor: #e6d4f0; /* Light Violet */
    --tableheaderBg: #6a0dad;
    --tableHeaderColor: #ffffff;
    --tableDataColor: #4a0072; /* Muted Violet */
    --BorderColor: #6a0dad;
    --HeadingColor: #4a0072;
    --headerSize: 1.6rem;
}

/* Styles/Common.css */

.teal {
    --PrimaryColor: #008080; /* Dark Teal */
    --secondaryColor: #e0f2f1; /* Light Teal */
    --tableheaderBg: #008080;
    --tableHeaderColor: #ffffff;
    --tableDataColor: #006666; /* Muted Teal */
    --BorderColor: #008080;
    --HeadingColor: #004d4d; /* Darker shade for headings */
    --headerSize: 1.6rem;
}

.navy {
    --PrimaryColor: #001f3f; /* Dark Navy */
    --secondaryColor: #dde2e6; /* Light Navy */
    --tableheaderBg: #001f3f;
    --tableHeaderColor: #ffffff;
    --tableDataColor: #3d5a80; /* Muted Navy Blue */
    --BorderColor: #001f3f;
    --HeadingColor: #23395d; /* Darker Blue for headings */
    --headerSize: 1.6rem;
}

.lavender {
    --PrimaryColor: #9370db; /* Lavender */
    --secondaryColor: #f3e8ff; /* Light Lavender */
    --tableheaderBg: #9370db;
    --tableHeaderColor: #ffffff;
    --tableDataColor: #7a5ebf; /* Muted Lavender */
    --BorderColor: #9370db;
    --HeadingColor: #5a3c8b; /* Darker Lavender for headings */
    --headerSize: 1.6rem;
}
