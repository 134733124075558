.Container {
    /* display: flex; */
    display: grid;
    grid-template-columns: 250px 1fr;
    position: relative;
    height: 100vh;
    overflow: hidden;
}
.cancel {
    background-color: red;
    color: white;
}
.sub_status {
    background-color: #ff9800;
    color: white;
    font-size: 0.8rem;
    padding: 0.53rem;
    border-radius: 10px;
}
.main_status {
    background-color: #4caf50;
    font-size: 0.8rem;
    color: white;
    padding: 0.53rem;
    border-radius: 10px;
}
.SideNavWrapper {
    display: grid;
    /* gap: 0.5rem; */
    background-color: white;
    /* border-right: 1px solid #ccc; */
    height: 100vh;
    gap: 2rem;
    align-content: baseline;
    overflow: auto;
    -ms-overflow-style: none;
    min-width: 73px;
    scrollbar-width: none;
}
.hamburger {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    transition: all 0.2s ease;
    border: 1px dotted #ccc;
}
.SideNavWrapper img {
    width: 3rem;
    justify-self: center;
    align-self: center;
    transition: all 0.5s ease-in-out;
    margin-top: 2rem;
}
.SideNavWrapper::-webkit-scrollbar {
    display: none;
}
.RightSide {
    position: relative;
    overflow: auto;
    flex-grow: 1;
    background-color: #f3f3f7;
}
.TopNav {
    display: flex;
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); */

    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(6px);
    /* background-color: red; */
    position: sticky;
    top: 0;
    /* border-bottom: 1px solid #ccc; */
    padding: 1.6rem 3rem;
    left: 0;
    right: 0;
    z-index: 10;
    /* background-color: white; */
    /* background-color: rgba( */
    /* 255, */
    /* 255, */
    /* 255, */
    /* 0.5 */
    /* ); */
    /* Initial background color with transparency */
    transition: all 0.3s ease;
}

.Input-wrapper {
    position: relative;
}
.Input-wrapper input {
    width: 25rem;
    height: 2.5rem;
    outline: none;
    text-indent: 1.8rem;
    border-radius: 20px;
    border: 1px solid #ccc;
}
.Input-wrapper .icon {
    position: absolute;
    top: 13px;
    left: 10px;
    color: gray;
    /* left: 0; */
}
.TopNav img {
    width: 2.5rem;
    height: 2rem;
    margin-right: 1rem;
    border-radius: 50%;
}
.user-img {
    display: flex;
    gap: 0.2rem;
    align-items: center;
}
.top-nav-right {
    display: flex;
    gap: 1rem;
}
.top-nav-right p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.95rem;
}
.bell-icon {
    padding: 0.5rem;
    background-color: white;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bell-icon::before {
    content: "•";
    font-size: 2rem;
    position: absolute;
    right: 7px;
    top: -12px;
    color: green;
}
.icon {
    color: inherit;
}
.icon-button {
    animation: rotateAnimation 6s linear infinite;
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
}
.UserSettings {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.TopNav_scrolled {
    background-color: rgba(
        255,
        255,
        255,
        1
    ); /* Change background color on scroll */
    /* backdrop-filter: blur(10px); */
    height: 40px;
    border-bottom: 1px solid #ccc;
}
@media screen and (max-width: 1000px) {
    .Container {
        grid-template-columns: 73px 1fr;
    }
}
@media screen and (max-width: 1200px) {
    .TopNav {
        flex-direction: column-reverse;
        padding: 1rem;
        gap: 1rem;
    }
    .top-nav-right {
        align-self: flex-end;
    }
    .Input-wrapper {
        align-self: flex-start;
    }
}
@media screen and (max-width: 560px) {
    .top-nav-right p {
        display: none;
    }
    .Input-wrapper {
        width: 90%;
    }
    .Input-wrapper input {
        width: 100%;
    }
}
@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
