.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}

.modal-content {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    overflow: scroll;
    min-width: 400px; /* Modal stops 300px from the right edge */
    width: fit-content;
    background: #ffffff;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    position: relative;
    animation: slide-in 300ms ease-out forwards; /* Slide-in animation */
}

.modal-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

@media screen and (max-width: 767px) {
    .modal-content {
        width: 85%;
        min-width: 80%;
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(100%); /* Start fully offscreen */
    }
    100% {
        transform: translateX(0); /* End position 300px from the right edge */
    }
}
